import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { Title } from '../../components/common';
import Layout from '../../components/layout';
import SwipableTabs from '../../components/swipeableTabs';
import { withTranslation } from '../../lib/translate';
import { getTransactionHistory } from '../../store/actions';
import { getLabel } from '../loyalty';
import HistoryTab from './historyTab';
import './index.css';

const tabLabelMap = {
  ordering: {
    first: 'Orders',
    second: 'Loyalty',
  },
  catalog: {
    first: 'Accrue',
    second: 'Redeem',
  },
};

class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getTransactionHistory();
  }

  getTransactionHistory = () => {
    this.props.dispatch(getTransactionHistory());
  };

  getNameOfLabelFirstTab = () => {
    const { transactionHistory } = this.props;

    const firstTabListAdditional = transactionHistory.map((item) => ({
      ...item,
      label: getLabel(item),
    }));

    return [
      ...firstTabListAdditional.map((item) => ({
        ...item,
        label: getLabel(item),
      })),
    ];
  };

  render() {
    const { __, transactionHistory, orderHistory, restaurants } = this.props;
    const { hasOrdering, hasLoyalty } = getConfig().flags;
    const tabLabels = hasOrdering ? tabLabelMap.ordering : tabLabelMap.catalog;

    const secondTabList = hasOrdering
      ? orderHistory
          .map((i) => ({
            label: i.restaurant_name + ' #' + i.id,
            transaction_date: i.collection_time,
            item: i,
          }))
      : transactionHistory
          .filter((i) => i.stamp_power < 0)
          .map((item) => ({ ...item, label: __('Points Redeemed') }));

    const firstTabType = __('points');
    const secondTabType = hasOrdering ? __('order') : __('points');

    return (
      <Layout
      showHamburger
      hasBottomNavigation
        title={getConfig().flags.hasOrdering ? __('Orders'): __('History')}
        color="transparent"
        scrollY={false}
        headerWithTitle
        {...((!hasLoyalty || !hasOrdering) && { style: { padding: 0 } })}
      >
        {hasLoyalty && hasOrdering ? (
          <SwipableTabs
            history={this.props.location}
            tabs={[
              {
                label: __(tabLabels.first),
                tabContent: (
                
                  <HistoryTab
                  type={secondTabType}
                  transactionHistory={secondTabList}
                  getTransactionHistory={this.getTransactionHistory}
                  restaurants={this.props.restaurants}
                  dispatch={this.props.dispatch}
                />
                ),
              },
              {
                label: __(tabLabels.second),
                tabContent: (
                  <HistoryTab
                  type={firstTabType}
                  transactionHistory={this.getNameOfLabelFirstTab()}
                  getTransactionHistory={this.getTransactionHistory}
                  restaurants={this.props.restaurants}
                  dispatch={this.props.dispatch}
                />
                ),
              },
            ]}
          />
        ) : hasLoyalty ? (
          <HistoryTab
            type={firstTabType}
            transactionHistory={this.getNameOfLabelFirstTab()}
            getTransactionHistory={this.getTransactionHistory}
            restaurants={this.props.restaurants}
            dispatch={this.props.dispatch}
          />
        ) : hasOrdering ? (
          <HistoryTab
            type={secondTabType}
            transactionHistory={secondTabList}
            getTransactionHistory={this.getTransactionHistory}
            restaurants={this.props.restaurants}
            dispatch={this.props.dispatch}
          />
        ) : null}
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  return {
    transactionHistory: state.orders.history || [],
    orderHistory: state.orders.orderHistory || [],
    restaurants: state.restaurants.restaurants || [],
  };
};

export default withRouter(withTranslation(connect(stateToProps)(History)));
