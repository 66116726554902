/* eslint-disable no-mixed-spaces-and-tabs */
import { IonCol, IonIcon, IonRow } from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NormalText, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import Modal from '../../components/modal';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isDefined } from '../../lib/utils';
import './index.css';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { chevronForward } from 'ionicons/icons';
import { QRCodeCanvas } from 'qrcode.react';

class GiftCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGiftCard: null,
      selectedVoucher: null
    };

  }

  componentDidMount() {
    if (this.props?.location?.state?.giftCard) {
      this.setState({ selectedGiftCard: this.props.location.state.giftCard })
    } else {
      forwardTo('/loyalty')
    }
  }
  render() {
    const { __, profile } = this.props;
    const { selectedGiftCard, selectedVoucher } = this.state
    return (
      <Loading>
        <Layout color='transparent' headerWithTitle={true} title={__('TOM Card')}>
          <div className='absolute-content scrollable-y'>
            <Title className='default-padding web-only'>{__('TOM Card')}</Title>

            <div className="loyalty-header">
              <div>
                <div>
                  <div>
                    <Subtitle className='block bold'>{__('Consumer ID')}</Subtitle>
                    <StrongText>{selectedGiftCard?.consumer_id}</StrongText>
                  </div>

                </div>
                <div style={{ justifySelf: 'flex-end', gridRow: 'span 2' }}>
                  <div className="qr-code">
                    {isDefined(selectedGiftCard?.qr_code) && selectedGiftCard?.qr_code !== '' ? (
                      <div className="qr-holder">
                        <QRCodeCanvas value={selectedGiftCard?.qr_code} size={150} />
                      </div>
                    ) : (
                      <div>
                        <h5>{__('NO QR CODE')}</h5>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <div>
                    <StrongText className='block'>{__('Remaining balance:')}</StrongText>
                    <StrongText>{Basket.formatPrice(selectedGiftCard?.value_remaining / 100,true)}</StrongText>
                  </div>


                </div>

              </div>

            </div>

            {selectedGiftCard?.vouchers?.length > 0 &&
              <>
                <Spacer size={1} />

                <NormalText className="default-padding block primary-color">
                  {__('TOM Card vouchers')}
                </NormalText>
                <div className='box-wrapper'>
                  {selectedGiftCard.vouchers.map(voucher => (
                    <div onClick={() => this.setState({ selectedVoucher: voucher })} className='voucher-box' style={{ cursor: 'pointer', display: "flex", alignItems: 'center', justifyContent: 'space-between' }}>
                      <div >
                        <StrongText className='block'>{voucher.reward.name}</StrongText>
                        <SmallText>{`${__('Expires')} ${moment(voucher.reward.expiry_date, 'YYYY/MM/DD').format(
                          'DD/MM/YY',
                        )}`}</SmallText>
                      </div>
                      <IonIcon style={{ height: '20px', width: '20px' }} icon={chevronForward} />
                    </div>
                  ))}
                </div>


              </>
            }

            {selectedGiftCard?.history?.length > 0 &&
              <>
                <Spacer size={1} />

                <NormalText className="default-padding block primary-color">
                  {__('TOM Card history')}
                </NormalText>
                <div className='transactions-wrapper box-wrapper'>

                  {selectedGiftCard.history.map((el, index) => (
                    <div key={index} className="small-padding ">
                      <IonRow key={index}>
                        <IonCol
                          size="8"
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <SmallText>{__(el.restaurant)}</SmallText>
                          <StrongText className="primary-color block ">
                            {el.type == 'value_added' ? __('Value added') : __('Value redeemed')}
                          </StrongText>
                          <SmallText>
                            {Basket.getDate(el.timestamp.replace('Z', '')).format(
                              'DD MMM YYYY h:mm a',
                            )}
                          </SmallText>{' '}
                        </IonCol>
                        <IonCol
                          ize="4"
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <div className="transaction-points-wrapper">
                            <NormalText
                              className={
                                el.type == 'value_redeemed' ? 'bold danger-color' : 'bold success-color'
                              }
                            >
                              {el.type == 'value_redeemed' ? '-' : '+'}
                              {el.value_str}
                            </NormalText>
                          </div>
                        </IonCol>
                      </IonRow>
                    </div>
                  ))}
                </div>

              </>
            }
            <Spacer size={1} />
            <NormalText className='block default-padding'>{`${__('TOM Card reference')}: ${selectedGiftCard?.id}`}</NormalText>

          </div>
        </Layout>
        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => {
            this.setState({ selectedVoucher: null });
            this.props.history.replace({ state: {} });
          }}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div style={{ textAlign: 'center' }}>
              <Title>{__('Voucher details')}</Title>
              <Spacer size={1} />

              <div className="voucher-modal-content">

                <Subtitle className='bold'>{selectedVoucher?.reward?.name}</Subtitle>
                <Spacer size={1} />

                <NormalText className="block">{__('Voucher info')}</NormalText>
                <Spacer size={1} />
                {selectedVoucher.reward.description ? (
                  <div

                    dangerouslySetInnerHTML={{ __html: selectedVoucher.reward.description }}
                  ></div>

                ) : null}
                <Spacer size={1} />

                {isDefined(selectedGiftCard?.qr_code) && selectedGiftCard?.qr_code !== '' ? (
                  <div className="qr-holder">
                    <QRCodeCanvas value={selectedGiftCard?.qr_code} size={150} />
                  </div>
                ) : (
                  <div>
                    <h5>{__('NO QR CODE')}</h5>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    )
  }
}

const mapStateToProps = (store) => {
  return {
    giftCards: store.profile.giftCards,
  };
};

export default connect(mapStateToProps)(withTranslation(GiftCard));
