import React from "react";
import { withTranslation } from "../../lib/translate";
import { withRouter } from 'react-router';
import { NormalText, Spacer, Title } from "../../components/common";
import { connect } from "react-redux";
import wrong from '../../assets/images/Deli_Wrong.svg';
import succees from '../../assets/images/Deli_Check.svg';

import { IonIcon } from "@ionic/react";
import { updateStripeOrder } from "../../store/actions";

class NativeOrderStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const orderId = searchParams.get('order')
        const status = searchParams.get('status')
        this.setState({ status })
        this.props.dispatch(updateStripeOrder({orderId,status}))
    }
    render() {
        const { __ } = this.props
        return (
            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                <Spacer />
                {this.state.status == 'success' ? <>
                    <IonIcon style={{ width: '70px', height: '70px' }} slot="icon-only" icon={succees} />
                    <Title style={{ margin: 0 }}>{__('Order created successfully')}</Title>
                </> : <>
                    <IonIcon style={{ width: '70px', height: '70px' }} slot="icon-only" icon={wrong} />
                    <Title >{__('Order failed or was canceled')}</Title>
                </>}

                <NormalText >{__('Please close this page')}</NormalText>
            </div>

        )
    }
}
const mapStateToProps = (store) => {

    return {

    };
};
export default connect(mapStateToProps)(withRouter(withTranslation(NativeOrderStatus)));
