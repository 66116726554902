import React, { Component } from 'react';
import BigNumber from '../../lib/bignumber';
import { IonRow, IonCol } from '@ionic/react';
import Basket from '../../lib/basket';
import { NormalText, SmallText, StrongText, Subtitle } from '../common';
import { withTranslation } from '../../lib/translate';
import { isDefined } from '../../lib/utils';
import './index.css';

class AmountPaid extends Component {
	render() {
		const { __, order, cards } = this.props;
		let amount_paid = isDefined(order.amount_paid) ? order.amount_paid : 0;
		if (amount_paid > 0) {
			// amount_paid were represents payed amount in cents
			amount_paid = new BigNumber(amount_paid).div(100).toNumber();
		}

		// let label = order.payment_token;
		// if (order.payment_token && cards && cards.length > 0) {
		// 	const card = cards.find((c) => c.id === order.payment_token);
		// 	if (card) {
		// 		const { brand, last4 } = card;
		// 		label = brand + ' ' + __('card ending') + ' **** ' + last4;
		// 	}
		// }
		return (
			<>
				<Subtitle className="default-padding">{__('Payment')}</Subtitle>
				<div className="box-content">
					{order && order.payment_token === 'Pay on collection' && order.status !== 'PAID' ? (
						<div className="ion-color-danger text-box">
							<SmallText color="red"> {__('PAYMENT DUE ON COLLECTION')}</SmallText>
						</div>
					) : (
						<>
							<IonRow className="no-border">
								<IonCol className="paddLR grow">
									<NormalText color="gray">{__('Yoco payment')}</NormalText>
									<div className="order-paid-status">{order.status.toLowerCase() === 'paid' ? __('Completed') : __('Processing')}</div>
								</IonCol>
								<IonCol className="righted paddLR">
									<StrongText>{Basket.formatPrice(amount_paid, true)}</StrongText>
								</IonCol>
							</IonRow>
							{order && order.process_message ? (
								<IonRow className="ion-color-danger">
									<IonCol className="paddLR ion-color-danger">
										<SmallText color="red">*** {__(order.process_message)} ***</SmallText>
									</IonCol>
								</IonRow>
							) : null}
						</>
					)}
				</div>
			</>
		);
	}
}

export default withTranslation(AmountPaid);
