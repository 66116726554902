import { IonAlert, IonButton, IonGrid, IonItem } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AmountPaid from '../../components/amountPaid';
import Box, { BoxHeader } from '../../components/box';
import { SmallText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import OrderContent from '../../components/orderContent';
import Basket, { createNewBasketInstance } from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { loading, logout } from '../../store/actions';
import { GET_ORDER_HISTORY } from '../../store/constants';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import api from '../../lib/api';
import Loading from '../../components/spinner';
import { Browser } from '@capacitor/browser';
import './index.css';

const isWeb = () => Capacitor.getPlatform() === 'web';

class OrderCompleted extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isCancel: false,
			orderId: null,
			restaurantId: null,
		};
		if (!isWeb()) {
			Browser.close();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.lastOrder) {
			if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
				const { lastOrder } = this.props;
				const orderArr = lastOrder;
				const now = moment();
				let cutoffTime = orderArr ? orderArr.cutoff_time : now;
				let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
				const utcOffset = cutoffTimeMoment.utcOffset();
				cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
				if (now.isBefore(cutoffTimeMoment)) {
					this.setState({
						isCancel: true,
						orderId: orderArr.id,
						restaurantId: orderArr.restaurant_id,
					});
				}
			}
		}
	}

	drawContentTitle = (__, deliveryOption, id, orderType) => {
		if (orderType === 'Outpost Drop-Off') {
			return (
				<Title>
					{__('Drop Point')} {__('Order')} #{id}
				</Title>
			);
		} else if (orderType === 'Delivery') {
			return (
				<Title>
					<strong>
						{__('Delivery')} {__('Order')} #{id}
					</strong>
				</Title>
			);
		} else if (orderType === 'Table') {
			return (
				<Title>
					<strong>
						{__('At Table')} {__('Order')} #{id}
					</strong>
				</Title>
			);
		} else if (orderType === 'scheduled-collection') {
			return (
				<Title>
					<strong>
						{__('Click & Collect')} #{id}
					</strong>
				</Title>
			);
		} else if (orderType === 'pick-up-at-counter') {
			return (
				<Title>
					<strong>
						{__('Pickup at Counter')} #{id}
					</strong>
				</Title>
			);
		} else {
			return (
				<Title>
					{__(orderType)} #{id}
				</Title>
			);
		}
	};

	drawContent = (__, order, orderType, basketInstance, status) => {
		if (!order?.is_gift) {
			if (orderType === 'Click & Collect') {
				return (
					<>
						<div className="box-content order-content-details">
							<div>
								<StrongText>{__('Collect from')}</StrongText>
								<StrongText className="block">{order.restaurant_name}</StrongText>
								<SmallText className="block">
									{__('Collect at')}: {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
								</SmallText>
								<SmallText className="block">
									{__('Order placed on')}: {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
								</SmallText>
							</div>
							<div className="order-status-wrapper">
								<div>{status && __(status)}</div>
							</div>
						</div>
					</>
				);
			} else if (orderType === 'Delivery') {
				return (
					<>
						<div className="box-content order-content-details">
							<div>
								<StrongText>{__('Delivery from')}</StrongText>
								<StrongText className="block">{order.restaurant_name}</StrongText>
								<SmallText className="block">
									{__('Order placed on')}: {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
								</SmallText>
								<SmallText className="block">
									{__('Delivery at')}: {basketInstance.formatOrderTime(true)}
								</SmallText>
							</div>
							<div className="order-status-wrapper">
								<div>{status && __(status)}</div>
							</div>
						</div>
					</>
				);
			} else if (orderType === 'Outpost Drop-Off') {
				return (
					<>
						<div className="box-content order-content-details">
							<div>
								<StrongText>{__('Drop-off at')}</StrongText>
								<StrongText className="block">{order.restaurant_name}</StrongText>
								<SmallText className="block">
									{__('Order placed on')}: {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
								</SmallText>
								<SmallText className="block">
									{__('Drop-off Time')}: {basketInstance.formatOrderTime(true)}
								</SmallText>
							</div>
							<div className="order-status-wrapper">
								<div>{status && __(status)}</div>
							</div>
						</div>
					</>
				);
			} else if (orderType === 'Table') {
				return (
					<>
						<div className="box-content order-content-details">
							<div>
								<StrongText>{__('Ordered at')}</StrongText>
								<StrongText className="block">{order.restaurant_name}</StrongText>
								<StrongText className="block">
									{__('Table Number')}-{order.table_name}
								</StrongText>
								<SmallText className="block">
									{__('Order placed on')}: {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
								</SmallText>
							</div>
							<div className="order-status-wrapper">
								<div>{status && __(status)}</div>
							</div>
						</div>
					</>
				);
			} else if (orderType === 'scheduled-collection') {
				return (
					<>
						<div className="box-content order-content-details">
							<div>
								<StrongText>{__('Collect from')}</StrongText>
								<StrongText className="block">{order.restaurant_name}</StrongText>
								<SmallText className="block">
									{__('Collect at')}: {basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
								</SmallText>
								<SmallText className="block">
									{__('Order placed on')}: {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
								</SmallText>
							</div>
							<div className="order-status-wrapper">
								<div>{status && __(status)}</div>
							</div>
						</div>
					</>
				);
			} else if (orderType === 'pick-up-at-counter') {
				return (
					<>
						<div className="box-content order-content-details">
							<div>
								<StrongText>{__('Collect from')}</StrongText>
								<StrongText className="block">{order.restaurant_name}</StrongText>
								<SmallText className="block">
									{__('Order placed on')}: {moment(order.created_at).format('DD MMM YYYY [at] h:mm a')}
								</SmallText>
							</div>
							<div className="order-status-wrapper">
								<div>{status && __(status)}</div>
							</div>
						</div>
					</>
				);
			}
		} else {
			return <></>;
		}
	};
	drawOrder = (order, orderCompletePage) => {
		const { __, lastOrder, cards } = this.props;
		const { id } = order;
		const basketInstance = createNewBasketInstance();
		basketInstance.recreateOrder(order);
		const orderType = basketInstance.getOrderType();
		//const paymentCard = this.props.cards.find(card => order.payment_token === card.id)
		return (
			<>
				{/* <div className="order-status">
		  {lastOrder && lastOrder.status ? __(lastOrder.status) : ''}
		</div> */}

				<Title className="default-padding web-only">{this.drawContentTitle(__, Basket.getDeliveryOption(), id, basketInstance.getOrderType())}</Title>
				{/* <Spacer size={2} /> */}
				<Box>
					<BoxHeader>
						<IonGrid className="okx-box-header-grid">{this.drawContent(__, order, orderType, basketInstance, lastOrder?.status)}</IonGrid>
					</BoxHeader>
					<Spacer size={1} />
					<OrderContent basketInstance={basketInstance} type="orderHistory" totalClass="uppercase" totalBorderedBottom={true} />
					<Spacer size={1} />

					<AmountPaid className="card-label" order={order} cards={cards} />
				</Box>
				{/* {orderType !== 'Click & Collect' ? (
					this.state.isCancel ? (
						<IonButton expand="block" fill="clear" className="link underlined" color="dark" onClick={() => this.handleCancelOrderModal(true)}>
							{__('Cancel this order')}
						</IonButton>
					) : null
				) : null} */}
				{this.props.profile.is_guest && (
					<IonButton
						color="primary"
						expand="block"
						onClick={() => {
							this.props.dispatch(logout());
						}}
					>
						{__('Done')}
					</IonButton>
				)}
			</>
		);
	};

	cancelOrder = () => {
		const { orderId, isCancel, restaurantId } = this.state;
		if (isCancel) {
			this.props.dispatch(checkCancelOrder(orderId, restaurantId));
			this.setState({ orderId: null, isCancel: false, restaurantId: null });
		}
	};

	noOrder = () => (
		<IonItem lines="none">
			<div className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
				{this.props.__('No order')}
			</div>
		</IonItem>
	);

	backHandler = () => {
		const { location } = this.props;
		if (location && location.state && location.state.completedOrder) {
			Basket.reset();
			const defaultRoute = getDefaultRoute(this.props.navConfig);
			forwardTo(defaultRoute.path);
		}
	};

	handleCancelOrderModal = (flag) => {
		this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
	};
	async componentDidMount() {
		const searchParams = new URLSearchParams(this.props.location.search);
		const status = searchParams.get('status')
		if (status === 'success' || status === 'failure') {
			Basket.reset();
		} else if (status === 'canceled' || status === 'cancel') {
			forwardTo('/order');
			return null;
		}
		this.props.dispatch({ type: GET_ORDER_HISTORY, loading: false })
		if (this.props?.location?.state?.isNative) {
			const orders = await api.getOrderHistory();
			const lastOrderStatus = orders[0]?.status;
			this.props.dispatch(loading(false));
			if (lastOrderStatus.toUpperCase() == 'CANCELED' || lastOrderStatus.toUpperCase() == 'CANCEL' || lastOrderStatus.toUpperCase() == 'NEW') {
				forwardTo('/order');
			} else {
				Basket.reset();
			}
		}
		this.props.dispatch(loading(false));
	}
	render() {
		const { __, lastOrder } = this.props;
		const order = this.props.order || lastOrder;
		const orderCompletePage = true;
		const headerLabel = `${__('Order')} ${order?.id && `#${order.id}`}`;

		return (
			<Loading >
				<Layout color="transparent" backHandler={this.backHandler} title={__(headerLabel)} headerWithTitle={true}>
					<div className="absolute-content order-details">
						<div className="scrollable-y">{order ? this.drawOrder(order, orderCompletePage) : this.noOrder()}</div>
					</div>
					<IonAlert
						isOpen={this.props.cancelOrderModal === true}
						onDidDismiss={() => this.handleCancelOrderModal(false)}
						header={__('Confirm')}
						message={__('Do you want to cancel this order?')}
						buttons={[
							{
								text: __('Cancel'),
								role: 'cancel',
								cssClass: 'secondary',
							},
							{
								text: __('Remove'),
								handler: () => this.cancelOrder(),
							},
						]}
					/>
				</Layout>
			</Loading>

		);
	}
}

const mapStateToProps = (store) => {
	const orders = store.orders;
	const { navConfig } = store.common;
	const { cancelOrderModal } = orders;
	const { restaurants } = store.restaurants;
	let lastOrder = null;
	const orderHistory = orders.orderHistory;
	if (orderHistory && orderHistory.length > 0) {
		lastOrder = orderHistory[0];
	}
	const { profile } = store.profile;

	return {
		lastOrder,
		cards: orders.cards || [],
		restaurants: restaurants || [],
		cancelOrderModal,
		profile,
		navConfig,
	};
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompleted)));
