import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import { SmallText } from '../common/index.js';
import './index.css';
import { forwardTo } from '../../lib/utils.js';
import { getConfig } from '../../appConfig.js';

class BottomNavigation extends Component {
  render() {
    const { __ } = this.props
    return (
      <div className='bottom-navigation-wrapper'>
        {getConfig().flags.hasOrdering ?
          <div className={`${location.pathname == '/delivery-options' ? 'navigation-active' : ''}`} onClick={() => forwardTo('/delivery-options')}>
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-tools-kitchen-2" viewBox="0 0 24 24" stroke-width="1.5" fill="none"  >
              <path d="M19 3v12h-5c-.023 -3.681 .184 -7.406 5 -12zm0 12v6h-1v-3m-10 -14v17m-3 -17v3a3 3 0 1 0 6 0v-3" />
            </svg>
            <SmallText>{__('Restaurants')}</SmallText>
          </div>
          : null}
        <div className={`${location.pathname == '/history' ? 'navigation-active' : ''}`} onClick={() => forwardTo('/history')}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-basket" viewBox="0 0 24 24" stroke-width="1.5" fill="none"  >
            <polyline points="7 10 12 4 17 10" />
            <path d="M21 10l-2 8a2 2.5 0 0 1 -2 2h-10a2 2.5 0 0 1 -2 -2l-2 -8z" />
            <circle cx="12" cy="15" r="2" />
          </svg>
          <SmallText>{getConfig().flags.hasOrdering?__('Orders'):__('History')}</SmallText>
        </div>
        <div className={`${location.pathname == '/loyalty' ? 'navigation-active' : ''}`} onClick={() => forwardTo('/loyalty')}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-star" viewBox="0 0 24 24" stroke-width="1.5" fill="none"  >
            <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
          </svg>
          <SmallText>{__('Loyalty')}</SmallText>
        </div>
        <div className={`${location.pathname == '/account' ? 'navigation-active' : ''}`} onClick={() => forwardTo('/account')}>
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings" viewBox="0 0 24 24" stroke-width="1.5" fill="none"  >
            <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" />
            <circle cx="12" cy="12" r="3" />
          </svg>
          <SmallText>{__('My account')}</SmallText>
        </div>
      </div>
    );
  }
}

export default withTranslation(BottomNavigation);
