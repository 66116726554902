import React from 'react';
import { IonItem, IonInput, IonButton, IonIcon } from '@ionic/react';
import { connect } from 'react-redux';
import { lockClosed, mail } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute, isWebConfig } from '../../lib/utils';
import { loginRequest } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import { FieldError, Title, Spacer, NormalText } from '../../components/common';
import { validateForm } from '../../lib/utils';
import SocialLogin from '../../components/SocialLogin';
import './index.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.formConfig = {
      username: { type: 'email', required: true },
      password: { type: 'password', required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleLogin() {
    let formErrors = validateForm(this.formConfig, this.state);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { protectedReferrer } = this.props;
      let referrer;
      if (this.props?.location?.state?.fromGiftVoucher) {
        referrer = '/gift-vouchers';
      } else {
        referrer = protectedReferrer;
      }
      const { username, password } = this.state;
      this.props.dispatch(loginRequest({ username, password, referrer }));
    }
  }

  checkLoginStatus = () => {
    const { loggedIn } = this.props.auth;
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path);
    }
  };

  componentDidUpdate() {
    this.checkLoginStatus();
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

  render() {
    const { __ } = this.props;
    const { username, password } = this.state;
    let hasSocialLogin = getConfig()?.flags && getConfig().flags.hasSocialLogin;
    const { hideSocialLoginNative } = getConfig()?.socialLogin || {};
    if (!isWebConfig() && hideSocialLoginNative) {
      hasSocialLogin = false
    }

    return (
      <Loading>
        <Layout showHamburger headerWithTitle title={__('Sign in')} color="transparent">
          <div className="absolute-content flex-row-wrapper">
            <div className="flex-min">
              <Title className='web-only default-padding'>{__('Sign in')}</Title>
            </div>
            <div >
              <div className='input-field-container'>

                <IonItem lines="none" className="input-field-wrapper">
                  {getConfig().theme.showInputIconsOnLogin ? (
                    <IonIcon slot="start" className="login-icon" size="small" icon={mail} />
                  ) : null}
                  <IonInput
                    placeholder={__('Email address')}
                    onIonInput={(e) => this.handleInput('username', e.target.value)}
                    onIonBlur={(e) => {
                      const usernameInput = document.querySelector(
                        'input[type="email"]:-webkit-autofill',
                      );
                      if (usernameInput) {
                        this.handleInput('username', usernameInput.value);
                      }
                      this.handleInput('username', e.target.value);
                    }}
                    clearInput
                    type="email"
                    pattern="email"
                    inputmode="email"
                    value={username}
                  />
                </IonItem>
                {this.state.formErrors.username &&
                  <FieldError className="field-error" value={__(this.state.formErrors.username)} />
                }

              </div>
              <div className='input-field-container'>
                <IonItem lines="none" className="input-field-wrapper">
                  {getConfig().theme.showInputIconsOnLogin ? (
                    <IonIcon slot="start" className="login-icon" size="small" icon={lockClosed} />
                  ) : null}
                  <PasswordInput
                    placeholder={__('Password')}
                    __={__}
                    onIonChange={(e) => this.handleInput('password', e.target.value)}
                    value={password}
                  />
                </IonItem>
                {this.state.formErrors.password &&
                  <FieldError className="field-error" value={__(this.state.formErrors.password)} />

                }
              </div>
              <Spacer size={1} />
              <IonButton
                expand="block"
                color="primary"
                className="default-button login-button"
                onClick={() => this.handleLogin()}
              >
                {__('Sign in')}
              </IonButton>
              <IonButton
                            style={{marginBottom:0}}

                expand="block"
                color="secondary"
                fill="clear"
                className="link underlined"
                onClick={() => forwardTo('/reset-password')}
              >
                {__('I forgot my password')}
              </IonButton>

              {!hasSocialLogin && <>
                <div className="block-separator">
                  <NormalText>{__('or')}</NormalText>
                </div>
                  <IonButton
                    color="primary"
                    expand='block'
                    onClick={() => forwardTo('/register')}
                  >
                    {__('Create an account')}
                  </IonButton>
              </>}

              {hasSocialLogin && (
                <>
                  <div className="block-separator">
                    <NormalText>{__('or')}</NormalText>
                  </div>
                  <Spacer size={1} />
                  <SocialLogin 
                    isRegister={false}
                  />
                </>
              )}
            </div>
            {hasSocialLogin &&
              <div className="flex-min ">
                <NormalText className="centered block">{__('Don’t have an account?')}</NormalText>
                <IonButton
                  color="secondary"
                  fill="clear"
                  className="link underlined"
                  onClick={() => forwardTo('/register')}
                >
                  {__('Sign up')}
                </IonButton>
              </div>

            }

          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, protectedReferrer } = state.profile;
  const { storedDeliveryAddress, storedPickUpPoint } = state.orders;
  const { navConfig } = state.common;
  return {
    auth,
    protectedReferrer,
    storedDeliveryAddress,
    storedPickUpPoint,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Login));
