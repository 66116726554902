import React from 'react';
import { IonModal, IonButton, IonIcon } from '@ionic/react';
import './index.css';
import { chevronBack, close } from 'ionicons/icons';
import { Title } from '../common';

export default class Modal extends React.Component {
	render() {
		const { children, title, action, actionLabel, className, scrollable, showCancelButton, cancelButtonLabel,closeIcon, ...rest } = this.props;
		const { onDidDismiss } = rest;

		return (
			<IonModal className={'modal-classic modal-wrapper' + (className ? ' ' + className : '')} {...rest}>
				<div className={'modal-classic-wrapper' + (scrollable ? ' scrollable-y' : '')}>
					{title ? (
						<div className="modal-classic-header">
							{onDidDismiss && !showCancelButton ? (
						<div className="modal-classic-closer" onClick={onDidDismiss}>
							<IonIcon icon={closeIcon?close:chevronBack} />
						</div>
					) : null}
							<Title>{title}</Title>
							{onDidDismiss && !showCancelButton ? (
								<div className="modal-classic-header-closer" onClick={onDidDismiss}>
									<ion-icon name="close" />
								</div>
							) : null}
						</div>
					) : (
						<>
							{onDidDismiss && !showCancelButton ? (
						<div className="modal-classic-closer" onClick={onDidDismiss}>
							<IonIcon icon={closeIcon?close:chevronBack} />
						</div>
					) : null}
						</>
					)}
					<div className="modal-classic-content">{children}</div>
					{action ? (
						<div className="modal-classic-action">
							<IonButton expand="block" color="primary" onClick={action}>
								{actionLabel || '--'}
							</IonButton>
						</div>
					) : null}
					{showCancelButton && (
						<div className="modal-classic-action">
							<IonButton expand="block" color="primary" fill="clear" className="link underlined" onClick={action}>
								{cancelButtonLabel || 'cancel'}
							</IonButton>
						</div>
					)}
				</div>
			</IonModal>
		);
	}
}
