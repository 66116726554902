import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { withTranslation } from '../../lib/translate';
import { validateProfileData } from '../../lib/utils';
import { setModal } from '../../store/actions';
import { NormalText, SmallText, StrongText } from '../common';
import './index.css';

const ValidateButton = ({ __, dispatch, profile, auth }) => {
  const isAuth = auth.loggedIn;
  const valid = validateProfileData(profile).isValid;
  if (!getConfig().appType.hasEmailValidationEnabled) {
    return null;
  }
  return (
    <>
      {isAuth && !valid ? (
        <>
          <IonGrid className="validate-button-wrapper bottom unverified validate-button">
            <IonRow onClick={() => dispatch(setModal('isVerfiedModalOpen', true))}>
              <IonCol className="">
                <NormalText onClick={() => dispatch(setModal('isVerfiedModalOpen', true))} className="validate-status">{__('Account not verified.')}{' '}<u>{__('Verify Account')}</u></NormalText>
                
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      ) : isAuth && valid ? (
        <IonGrid className="validate-button-wrapper validate-button valid">
          <IonRow>
            <IonCol className="">
              <NormalText className="validate-status">{__('Verified')}</NormalText>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { isVerfiedModalOpen, profile, auth } = store.profile;
  return {
    isVerfiedModalOpen,
    profile,
    auth,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(ValidateButton)));
