import { IonButton, IonCheckbox, IonIcon, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import { getIkentooMenu, getIkentooMenusForLocation, setDeliveryOption, setMyLocation, storeItemWeb } from '../../store/actions';
import { chevronDown, chevronForwardOutline, chevronUp } from 'ionicons/icons';
import { NormalText, SmallText, Title } from '../../components/common';
import { getDistanceUnit, goBack, isWebConfig } from '../../lib/utils';
import location from '../../assets/images/map-pin-filled.svg';
import { getMenusForSelectedTime } from '../clickAndCollect';
import { withTranslation } from '../../lib/translate';
import { Geolocation } from '@capacitor/geolocation';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { getConfig } from '../../appConfig';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import './index.css';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

class BrowseMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: '',
			isLocationAllowed: false,
			noTableRestaurants: false,
			useSearchParams: false,
			selectedRestaurant: props.location.state && props.location.state.selectedRestaurant ? props.location.state.selectedRestaurant.restaurant_id : null,
			showMenusList: false,
			showRestaurants: true,
			menuDetailsArr: [],
      selectedMenu: null,
		};
	}

	async componentDidMount() {
		const { restaurants } = this.props;
		await this.position();
		const searchParams = new URLSearchParams(this.props.location.search);
		const availableResturants = restaurants.filter((restaurant) => restaurant.can_browse_menu && restaurant.is_published);
		const selectedRestaurant = availableResturants.find((restaurant) => restaurant.id === this.state.selectedRestaurant);
		const option = (getConfig().delivery || []).find((d) => d.id === 'browse-menu');
		Basket.reset();
		this.props.dispatch(storeItemWeb(null));
		Basket.setOrderType('browse-menu');
		Basket.setDeliveryOption(option);
		this.props.dispatch(setDeliveryOption(option));
		if (searchParams.get('bl_id')) {
			const selectedRestaurant = availableResturants.find((restaurant) => restaurant.business_location_id === searchParams.get('bl_id'));
			this.setState(
				{
					selectedRestaurant: selectedRestaurant.id,
					pickTime: moment().tz(getConfig().timezone).format('HH:mm')
				}, () => this.chooseMenusForLocation()
			);
		} else if (availableResturants.length === 1) {
			this.setState(
				{
					selectedRestaurant: availableResturants[0].id,
					pickTime: moment().tz(getConfig().timezone).format('HH:mm'),
					showMenusList: true
				}, () => this.chooseMenusForLocation()
			);

		} else if (selectedRestaurant && selectedRestaurant.can_browse_menu && selectedRestaurant.is_published) {
			this.setState(
				{
					selectedRestaurant: selectedRestaurant.id,
					pickTime: moment().tz(getConfig().timezone).format('HH:mm')
				}
			);
		} else if (availableResturants.length > 0) {
			let byDistance = availableResturants;
			byDistance.sort(function (a, b) {
				return a.distance - b.distance;
			});
			this.setState(
				{
					selectedRestaurant: byDistance[0].id,
					pickTime: moment(Date.now()).format('HH:mm')
				}
			);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { restaurants } = this.props;
		if (this.state.selectedRestaurant !== prevState.selectedRestaurant && this.state.selectedRestaurant) {
			const selectedRestaurant = restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant);
			Basket.setRestaurant(selectedRestaurant);
		}
	}

	chooseMenusForLocation = () => {
		const { __, restaurants, dispatch } = this.props;
		const { selectedRestaurant, pickTime } = this.state;
		const choosenRestaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurant);
		const businessLocationId = choosenRestaurant.business_location_id;
		const browse_menu_json_time_selector = choosenRestaurant ? choosenRestaurant.browse_menu_json_time_selector : [];
		this.setState({ showMenusList: true });
		dispatch(
			getIkentooMenusForLocation(
				businessLocationId, 
				{
					pickTime,
					json_time_selector: browse_menu_json_time_selector,
				},
				browse_menu_json_time_selector.length === 1 && this.isMenuAvailableToday(browse_menu_json_time_selector[0].availability) ? false : true
			),
		);
	};

	continueOnMenu = () => {
		const { restaurants, dispatch } = this.props;
		const { selectedIkentooMenu, selectedRestaurant } = this.state;
		if (selectedRestaurant && selectedIkentooMenu) {
			const choosenRestaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurant);
			const businessLocationId = choosenRestaurant.business_location_id;
			this.setState({ showMenusList: true });
			dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId, true));
		} else {
			this.setState({ error: 'Please select location menu' });
		}
	};

	position = async () => {
		const myLocation = {
			latitude: null,
			longitude: null
		};
		try {
			const coordinates = await Geolocation.getCurrentPosition({
				enableHighAccuracy: false,
			});
			myLocation.latitude = coordinates.coords.latitude;
			myLocation.longitude = coordinates.coords.longitude;
			this.setState({ isLocationAllowed: true });
		} catch (error) {
			console.log('Error getting location: ' + error.message);
			this.setState({ isLocationAllowed: false });
		}
		this.props.dispatch(setMyLocation(myLocation));
};

	changeIkentooMenus = (event) =>
		this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
			Basket.setMenu(event.detail.value);
		});


	backHandler = () => {
		const { showRestaurants, showMenusList } = this.state;
		
		if (showRestaurants && !showMenusList) {
			goBack();
		}else{
			this.setState({
				showRestaurants: showMenusList ? true : false,
				showMenusList: false,
				selectedIkentooMenu: null
			}, ()=> Basket.setMenu(null));
		}
	};

	toggleMenuDetails = (index) => {
    const menuDetailsArr = JSON.parse(JSON.stringify(this.state.menuDetailsArr));
    if (menuDetailsArr.includes(index)) {
      this.setState({ menuDetailsArr: menuDetailsArr.filter((item) => item != index) });
    } else {
      this.setState({ menuDetailsArr: [...menuDetailsArr, index] });
    }
  };

	isMenuAvailableToday = (menuAilability) => {
		const timeFormat = 'HH:mm';
		const now = moment().tz(getConfig().timezone);
		const dayNumber = now.day() === 7 ? 0 : now.day();
		const todayMenuAilability = menuAilability.find((ailability) => ailability.d === `w${dayNumber}`);
		if(todayMenuAilability){
			const startTime = moment(todayMenuAilability.start, timeFormat);
			const endTime = moment(todayMenuAilability.end, timeFormat);
			return now.isBetween(startTime, endTime)
		}
		return false;
	}

	getSlotDay = (slot) => {
		if(slot && slot.d){
			const dayNumber = parseInt(slot.d.replace('w',''));
			return(days[dayNumber])
		}
		return '';
	}

	render() {
		const { __, restaurants } = this.props;
		const { selectedIkentooMenu, pickTime, selectedRestaurant, noTableRestaurants, showRestaurants, showMenusList } = this.state;
		const availableResturants = restaurants.filter((restaurant) => restaurant.can_browse_menu && restaurant.is_published);
		const store = availableResturants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
		const menus = store && store?.browse_menu_json_time_selector ? store?.browse_menu_json_time_selector : [];
		return (
			<Loading transparent>
				<Layout showHamburger={!showMenusList} backHandler={this.backHandler} title={showMenusList ? __('Select a menu') : __('Select a location')} headerWithTitle={true} color="transparent">
					<div className="flex-row-wrapper absolute-content">
						{showRestaurants && !showMenusList && (
							<>
								<div className="click-and-collect-title">
									<Title className="web-only">{showMenusList ? __('Select a menu') : __('Select a location')}</Title>
								</div>
								<div className="browse-menu-locations">
									<IonList class="box-wrapper">
										{availableResturants.map((store, i) => (
											<IonItem
												key={i}
												disabled={store.opened === 'Closed' || noTableRestaurants}
												onClick={() =>
													this.setState({
														selectedRestaurant: store.id
													},() => this.chooseMenusForLocation())
												}
												lines="none"
											>
												<IonLabel className="break-space">
													<NormalText className="primary-color">{__(store.name)}</NormalText>
													<SmallText className="block">{__(store.address)}</SmallText>
													{store?.distance && (
														<div className="distance-wrapper">
															<IonIcon className="location-icon" icon={location} ></IonIcon>
															<SmallText className="block bold-description click-collect-small-description">
																{store.distance < 1 ? store.distance.toFixed(1) : store.distance.toFixed(0)} {__(getDistanceUnit())}
															</SmallText>
														</div>
													)}
												</IonLabel>
												<IonIcon className="forward-arrow-icon" icon={chevronForwardOutline}></IonIcon>
											</IonItem>
										))}
									</IonList>
								</div>
							</>
						)}
						{(menus.length > 1 && showMenusList) || (showMenusList && menus.length === 1 && !this.isMenuAvailableToday(menus[0].availability) ) ? (
							<>
								<div className="click-and-collect-title">
									<Title className="web-only">{__('Select a menu')}</Title>
								</div>
								<div className="browse-menu-locations">
									<IonList class=" box-wrapper">
										<IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
											{menus.map((menu, i) => {
												const { menuId, menuName } = menu;
												const isMenuAvailable = this.isMenuAvailableToday(menu.availability);
												return (
													<div className="menu-item-wrapper">
														<div key={i} className="menu-item">
															<IonItem disabled={!isMenuAvailable} lines='none'>
																<IonRadio color={isWebConfig() ? 'primary' : 'white'} slot="start" value={menuId} />
																<div>
																<NormalText className="primary-color">{__(menuName)}</NormalText>
																	<SmallText color={isMenuAvailable ? 'success' : 'danger'} className={`${isMenuAvailable ? 'success-color' : 'danger-color'} block`}>{isMenuAvailable? 'Current Service' : 'Unavailable'}</SmallText>
																</div>
															</IonItem>
															<div onClick={() => this.toggleMenuDetails(i)} >
																<IonIcon
																	icon={this.state.menuDetailsArr.includes(i) ? chevronUp : chevronDown}
																></IonIcon>
															</div>
														</div>
														{this.state.menuDetailsArr.includes(i) && (
																<div className="menu-times">
																	{menu.availability.map((slot, i) => (
																		<>
																			{slot.start ? (
																				<NormalText className="block capitalize">
																					<NormalText className="menu-day primary-color">
																						{__(this.getSlotDay(slot))}:
																					</NormalText>{' '}
																					<NormalText>
																						{slot.start}-{slot.end}
																					</NormalText>
																				</NormalText>
																			) : null}
																		</>
																	))}
																</div>
															)}
													</div>
												)
											})}
										</IonRadioGroup>
									</IonList>
								</div>
								<div className="click-and-collect-button-wrapper">
									<IonButton
										disabled={pickTime && menus.length > 0 && selectedIkentooMenu ? false : true}
										expand="block"
										color="primary"
										className="uppercase okx-font-secondary"
										onClick={() => this.continueOnMenu()}
									>
										{__('Continue')}
									</IonButton>
								</div>
							</>
						) : null}
					</div>
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { deliveryOption } = state.orders;
	const { restaurants, ikentooMenusForLocation } = state.restaurants;
	const { isChooseMenuModalOpen, navConfig } = state.common;
	return {
		navConfig,
		deliveryOption,
		restaurants: restaurants || [],
		profile: state.profile.profile,
		isChooseMenuModalOpen: isChooseMenuModalOpen,
		ikentooMenusForLocation: ikentooMenusForLocation || [],
	};
};

export default connect(stateToProps)(withTranslation(BrowseMenu));
